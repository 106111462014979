import React from "react";
import InfluencerCard from "./InfluencerCard";
// const results = [
//   {
//     uuid: "1eec4b4a-8c63-4ae9-bb8e-13b5f828fd50",
//     platformUuid: "b253ddbc-b8d9-45ab-8e8b-58219b1d1bcc",
//     userUuid: "9d03dec0-fdd1-44fa-a189-67107e50c4c5",
//     categoryUuid: "2a928173-cffa-4b23-8a68-97a2a40396e7",
//     platformName: "Test",
//     platformUrl:
//       "https://hicoders-team-ah4mff2y.atlassian.net/jira/software/projects/SCRUM/boards/1?sprintStarted=true",
//     platformEmail: "test@test.com",
//     platformGeo: "Latin America",
//     followers: 234234,
//     averageViews: 7718000,
//     averageAge: "13-17",
//     priceFrom: 23,
//     priceTo: 25,
//     bio: "Lorem",
//     imageUrl: null,
//     isApproved: true,
//     createdAt: "2024-05-17T17:41:57.000Z",
//     updatedAt: "2024-05-17T17:41:57.000Z",
//   },
//   {
//     uuid: "9a6b304c-db00-435a-9468-182ca7762b90",
//     platformUuid: "4ea1cddc-0db3-422b-a56d-7459119bdd20",
//     userUuid: "9d03dec0-fdd1-44fa-a189-67107e50c4c5",
//     categoryUuid: "32bb9f57-d2bd-4603-9423-3830ea18fe21",
//     platformName: "Name1212",
//     platformUrl:
//       "https://www.youtube.com/watch?v=dq84lWCGatU&list=RDlbgZcKJWzVM&index=27",
//     platformEmail: "radmyr.deriabin11@gmail.com",
//     platformGeo: "Latin America",
//     followers: 123123,
//     averageViews: 9226000,
//     averageAge: "13-17",
//     priceFrom: 23,
//     priceTo: 234,
//     bio: "dfgfrbrgbtg",
//     imageUrl: null,
//     isApproved: true,
//     createdAt: "2024-05-11T14:22:24.000Z",
//     updatedAt: "2024-05-11T16:06:05.000Z",
//   },
//   {
//     uuid: "bba7bf87-c9e6-4cb7-9a81-59090f7bb48d",
//     platformUuid: "b253ddbc-b8d9-45ab-8e8b-58219b1d1bcc",
//     userUuid: "9d03dec0-fdd1-44fa-a189-67107e50c4c5",
//     categoryUuid: "2a928173-cffa-4b23-8a68-97a2a40396e7",
//     platformName: "WER",
//     platformUrl:
//       "https://www.youtube.com/watch?v=dq84lWCGatU&list=RDlbgZcKJWzVM&index=27",
//     platformEmail: "radmyr.deriabin11@gmail.com",
//     platformGeo: "Latin America",
//     followers: 123,
//     averageViews: 17918000,
//     averageAge: "13-17",
//     priceFrom: 11,
//     priceTo: 12,
//     bio: "sdvfdv",
//     imageUrl: null,
//     isApproved: true,
//     createdAt: "2024-05-11T14:23:39.000Z",
//     updatedAt: "2024-05-16T12:48:06.000Z",
//   },
//   {
//     uuid: "d3901e9e-7bed-41d9-95bf-6ec663543162",
//     platformUuid: "4ea1cddc-0db3-422b-a56d-7459119bdd20",
//     userUuid: "f9e3da41-f3c0-49fb-b0c4-30b7e3a5be5a",
//     categoryUuid: "2a928173-cffa-4b23-8a68-97a2a40396e7",
//     platformName: "Name",
//     platformUrl:
//       "https://www.youtube.com/watch?v=dq84lWCGatU&list=RDlbgZcKJWzVM&index=27",
//     platformEmail: "radmyr.deriabin11@gmail.com",
//     platformGeo: "Latin America",
//     followers: 1233312,
//     averageViews: 1939000,
//     averageAge: "18-35",
//     priceFrom: 12,
//     priceTo: 123,
//     bio: "evefvfvrfv",
//     imageUrl: null,
//     isApproved: true,
//     createdAt: "2024-05-12T16:07:33.000Z",
//     updatedAt: "2024-05-12T16:07:33.000Z",
//   },
//   {
//     uuid: "d3401e9e-7bed-41d9-95bf-6ec663543162",
//     platformUuid: "4ea1cddc-0db3-422b-a56d-7459119bdd20",
//     userUuid: "f9e3da41-f3c0-49fb-b0c4-30b7e3a5be5a",
//     categoryUuid: "2a928173-cffa-4b23-8a68-97a2a40396e7",
//     platformName: "Name",
//     platformUrl:
//       "https://www.youtube.com/watch?v=dq84lWCGatU&list=RDlbgZcKJWzVM&index=27",
//     platformEmail: "radmyr.deriabin11@gmail.com",
//     platformGeo: "Latin America",
//     followers: 1233312,
//     averageViews: 1939000,
//     averageAge: "18-35",
//     priceFrom: 12,
//     priceTo: 123,
//     bio: "evefvfvrfv",
//     imageUrl: null,
//     isApproved: true,
//     createdAt: "2024-05-12T16:07:33.000Z",
//     updatedAt: "2024-05-12T16:07:33.000Z",
//   },
//   {
//     uuid: "d3951e9e-7bed-41d9-95bf-6ec663543162",
//     platformUuid: "4ea1cddc-0db3-422b-a56d-7459119bdd20",
//     userUuid: "f9e3da41-f3c0-49fb-b0c4-30b7e3a5be5a",
//     categoryUuid: "2a928173-cffa-4b23-8a68-97a2a40396e7",
//     platformName: "Name",
//     platformUrl:
//       "https://www.youtube.com/watch?v=dq84lWCGatU&list=RDlbgZcKJWzVM&index=27",
//     platformEmail: "radmyr.deriabin11@gmail.com",
//     platformGeo: "Latin America",
//     followers: 1233312,
//     averageViews: 1939000,
//     averageAge: "18-35",
//     priceFrom: 12,
//     priceTo: 123,
//     bio: "evefvfvrfv",
//     imageUrl: null,
//     isApproved: true,
//     createdAt: "2024-05-12T16:07:33.000Z",
//     updatedAt: "2024-05-12T16:07:33.000Z",
//   },
//   {
//     uuid: "d3921e9e-7bed-41d9-95bf-6ec663543162",
//     platformUuid: "4ea1cddc-0db3-422b-a56d-7459119bdd20",
//     userUuid: "f9e3da41-f3c0-49fb-b0c4-30b7e3a5be5a",
//     categoryUuid: "2a928173-cffa-4b23-8a68-97a2a40396e7",
//     platformName: "Name",
//     platformUrl:
//       "https://www.youtube.com/watch?v=dq84lWCGatU&list=RDlbgZcKJWzVM&index=27",
//     platformEmail: "radmyr.deriabin11@gmail.com",
//     platformGeo: "Latin America",
//     followers: 1233312,
//     averageViews: 1939000,
//     averageAge: "18-35",
//     priceFrom: 12,
//     priceTo: 123,
//     bio: "evefvfvrfv",
//     imageUrl: null,
//     isApproved: true,
//     createdAt: "2024-05-12T16:07:33.000Z",
//     updatedAt: "2024-05-12T16:07:33.000Z",
//   },
// ];

const Results = ({ categories, results }) => {
  const getCategoryName = (uuid) => {
    const category = categories.find((cat) => cat.uuid === uuid);
    return category ? category.name : "Unknown Category";
  };
  return (
    <div className="results flex flex-col gap-4">
      {results.length > 0 ? (
        results.map((influencer) => (
          <InfluencerCard
            key={influencer.uuid}
            influencer={influencer}
            getCategoryName={getCategoryName}
          />
        ))
      ) : (
        <p>No results found</p>
      )}
    </div>
  );
};

export default Results;
