import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  FaEnvelope,
  FaTwitch,
  FaInstagram,
  FaTiktok,
  FaTelegram,
  FaYoutube,
} from "react-icons/fa";

const validationSchema = Yup.object({
  contactEmail: Yup.string().email("Invalid email").required("Required"),
  twitch: Yup.string().url("Invalid URL"),
  instagram: Yup.string().url("Invalid URL"),
  tiktok: Yup.string().url("Invalid URL"),
  youtube: Yup.string().url("Invalid URL"),
  telegram: Yup.string().url("Invalid URL"),
}).test(
  "at-least-one-social",
  "At least one social network URL must be provided",
  (values) => {
    return (
      values.twitch ||
      values.instagram ||
      values.tiktok ||
      values.youtube ||
      values.telegram
    );
  }
);

const initialValues = {
  contactEmail: "",
  twitch: "",
  instagram: "",
  tiktok: "",
  youtube: "",
  telegram: "",
};

const CollaborationForm = ({ setShowCollaborationForm }) => {
  const handleSubmit = (values) => {
    console.log(values);
    // handle form submission
    setShowCollaborationForm(false);
  };

  return (
    <div className="flex flex-col h-full text-white px-16 py-8">
      <h1 className="text-5xl mb-4">Fill Out The Form For Collaboration</h1>
      <p className="mb-10">
        Add the social networks that you have. After sending the application we
        will contact you by your contact email.
      </p>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors }) => (
          <Form className="space-y-8">
            <div className="grid grid-cols-2 gap-8">
              <div>
                <label className="block mb-2 text-lg">
                  <FaEnvelope className="inline mr-2 text-infl" /> Contact
                  Information*
                </label>
                <Field
                  type="email"
                  name="contactEmail"
                  placeholder="Contact Email"
                  className="w-full p-2 bg-inherit border border-infl rounded"
                />
                <ErrorMessage
                  name="contactEmail"
                  component="div"
                  className="text-red-500 mt-1"
                />
              </div>
              <div>
                <label className="block mb-2 text-lg">
                  <FaTwitch className="inline mr-2 text-infl" /> Twitch
                </label>
                <Field
                  type="url"
                  name="twitch"
                  placeholder="www.company.com"
                  className="w-full p-2 bg-inherit border border-infl rounded"
                />
                <ErrorMessage
                  name="twitch"
                  component="div"
                  className="text-red-500 mt-1"
                />
              </div>
              <div>
                <label className="block mb-2 text-lg">
                  <FaInstagram className="inline mr-2 text-infl" /> Instagram
                </label>
                <Field
                  type="url"
                  name="instagram"
                  placeholder="www.company.com"
                  className="w-full p-2 bg-inherit border border-infl rounded"
                />
                <ErrorMessage
                  name="instagram"
                  component="div"
                  className="text-red-500 mt-1"
                />
              </div>
              <div>
                <label className="block mb-2 text-lg">
                  <FaTiktok className="inline mr-2 text-infl" /> TikTok
                </label>
                <Field
                  type="url"
                  name="tiktok"
                  placeholder="www.company.com"
                  className="w-full p-2 bg-inherit border border-infl rounded"
                />
                <ErrorMessage
                  name="tiktok"
                  component="div"
                  className="text-red-500 mt-1"
                />
              </div>
              <div>
                <label className="block mb-2 text-lg">
                  <FaYoutube className="inline mr-2 text-infl" /> YouTube
                </label>
                <Field
                  type="url"
                  name="youtube"
                  placeholder="www.company.com"
                  className="w-full p-2 bg-inherit border border-infl rounded"
                />
                <ErrorMessage
                  name="youtube"
                  component="div"
                  className="text-red-500 mt-1"
                />
              </div>
              <div>
                <label className="block mb-2 text-lg">
                  <FaTelegram className="inline mr-2 text-infl" /> Telegram
                </label>
                <Field
                  type="url"
                  name="telegram"
                  placeholder="www.company.com"
                  className="w-full p-2 bg-inherit border border-infl rounded"
                />
                <ErrorMessage
                  name="telegram"
                  component="div"
                  className="text-red-500 mt-1"
                />
              </div>
              {errors["at-least-one-social"] && (
                <div className="col-span-2 text-red-500">
                  At least one social network URL must be provided
                </div>
              )}
              <p className="text-lg mb-4 col-span-1">
                We will familiarize ourselves in detail with your channels and
                formulate our unique proposal for the interaction and
                development of your networks and our platform.
              </p>
              <div className="col-span-1"></div>
              <div className="col-span-1 text-center">
                <button
                  type="submit"
                  className="inflooms-btn inflooms-btn-green rounded-xl px-8 py-2 w-1/2"
                  disabled={isSubmitting}
                  style={{
                    background:
                      "linear-gradient(111deg, #08A1A5 0%, rgba(54, 83, 174, 0) 100%)",
                    borderColor: "#08A1A5",
                  }}
                >
                  Send
                </button>
              </div>
              <div className="col-span-1 text-center">
                <button
                  type="button"
                  className="inflooms-btn inflooms-btn-red rounded-xl px-8 py-2 w-1/2"
                  onClick={() => setShowCollaborationForm(false)}
                  style={{
                    background:
                      "linear-gradient(111deg, #A50808 0%, rgba(174, 54, 54, 0) 100%)",
                    borderColor: "#A50808",
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CollaborationForm;
