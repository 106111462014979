import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import DashboardNav from "../DashboardNav";
import Platforms from "./Platforms";
import AddPlatform from "./AddPlatform";
import axios from "axios";
import JoinForm from "./JoinForm";
import ManageSettings from "../Shared/ManageSettings";
import Modal from "../Shared/Modal"; // Import Modal component
import PasswordChangeForm from "../Shared/PasswordChangeForm";

const InfluencerDashboard = () => {
  const { user, logout } = useAuth();
  const [selectedMenu, setSelectedMenu] = useState("platforms");
  const [categories, setCategories] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [platformProfiles, setPlatformProfiles] = useState([]);
  const [, setError] = useState(null);
  const [editingProfile, setEditingProfile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogout = async () => {
    await logout();
  };

  const handleLogoutClick = () => {
    setIsModalOpen(true);
  };

  const handleConfirmLogout = async () => {
    setIsModalOpen(false);
    await handleLogout();
  };

  const handleAddPlatform = () => {
    setSelectedMenu("add");
  };

  const handleCancel = () => {
    setSelectedMenu("platforms");
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/categories`,
        { withCredentials: true }
      );
      setCategories(response.data);
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    }
  };

  const fetchPlatforms = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/platforms`,
        { withCredentials: true }
      );
      setPlatforms(response.data);
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    }
  };

  const fetchPlatformProfiles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/profiles/user/all`,
        { withCredentials: true }
      );
      setPlatformProfiles(response.data);
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchPlatforms();
    fetchPlatformProfiles();
  }, []);

  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      const payload = {
        platformName: values.platformName,
        platformUrl: values.platformUrl,
        platformEmail: values.platformEmail,
        platformGeo: values.platformGeo,
        categoryUuid: values.category,
        followers: values.followers,
        averageViews: values.averageViews,
        averageAge: values.averageAge,
        priceFrom: values.priceFrom,
        priceTo: values.priceTo,
        bio: values.bio,
        platformUuid: values.platform,
      };

      let response;
      if (values.uuid) {
        response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/profiles/${values.uuid}`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/profiles/`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }

      const createdProfile = response.data;

      // Step 2: Upload the image if there is one
      if (values.image) {
        const formData = new FormData();
        formData.append("image", values.image);
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/profiles/upload/${createdProfile.uuid}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      resetForm();
      setEditingProfile(null);
      fetchPlatformProfiles();
    } catch (error) {
      setError(error.response ? error.response.data.message : error.message);
    }
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case "platforms":
        return (
          <div className="bg-background h-full w-full">
            <Platforms
              onAddPlatform={handleAddPlatform}
              categories={categories}
              platforms={platforms}
              platformProfiles={platformProfiles}
              setSelectedMenu={setSelectedMenu}
              onSubmit={handleFormSubmit}
              refreshProfiles={fetchPlatformProfiles}
              editingProfile={editingProfile}
              setEditingProfile={setEditingProfile}
            />
          </div>
        );
      case "add":
        return (
          <div className="bg-background h-full w-full">
            <AddPlatform
              categories={categories}
              platforms={platforms}
              onCancel={handleCancel}
              onSubmit={handleFormSubmit} // Pass handleFormSubmit to AddPlatform
            />
          </div>
        );
      case "join":
        return (
          <div className="bg-background h-full w-full">
            <JoinForm /> {/* Pass handler */}
          </div>
        );
      case "manage":
        return (
          <div className="bg-background h-full w-full">
            <ManageSettings />
          </div>
        );
      case "safety":
        return (
          <div className="bg-background h-full w-full">
            <PasswordChangeForm />
          </div>
        );
      default:
        return <div className="bg-background h-full w-full">Home Content</div>;
    }
  };

  if (!user) {
    return <div>Loading or not logged in...</div>;
  }

  return (
    <div className="flex flex-col h-screen">
      <div className="fixed top-0 left-0 right-0 z-10">
        <DashboardNav />
      </div>
      <div className="flex flex-1 pt-16">
        <nav
          className="fixed top-16 left-0 bottom-0 w-1/6 text-white h-full"
          style={{ background: "linear-gradient(to bottom, #00090C, #08A1A5)" }}
        >
          <ul className="space-y-6 border-y border-infl py-6 px-5">
            <li
              className={`cursor-pointer ${
                selectedMenu === "platforms" && "text-infl"
              }`}
              onClick={() => setSelectedMenu("platforms")}
            >
              <i className="fa-solid fa-id-card mr-2 text-infl"></i> My
              Platforms
            </li>
            <li
              className={`cursor-pointer ${
                selectedMenu === "add" && "text-infl"
              }`}
              onClick={() => setSelectedMenu("add")}
            >
              <i className="fa-solid fa-plus mr-2 text-infl"></i> Add Platform
            </li>
            <li
              className={`cursor-pointer ${
                selectedMenu === "join" && "text-infl"
              }`}
              onClick={() => setSelectedMenu("join")}
            >
              <i className="fa-regular fa-pen-to-square mr-2 text-infl"></i>{" "}
              Join Inflooms
            </li>
          </ul>
          <ul>
            <li className="my-6 px-5">
              <span className="text-sm block mb-2 mr-2 text-infl">
                {user.email}
              </span>
            </li>
          </ul>
          <ul className="space-y-6 px-5">
            <li
              className={`cursor-pointer ${
                selectedMenu === "manage" && "text-infl"
              }`}
              onClick={() => setSelectedMenu("manage")}
            >
              <i className="fa-solid fa-gear mr-2 text-infl"></i> Manage
              Settings
            </li>
            <li
              className={`cursor-pointer ${
                selectedMenu === "safety" && "text-infl"
              }`}
              onClick={() => setSelectedMenu("safety")}
            >
              <i className="fa-solid fa-lock mr-2 text-infl"></i> Safety
            </li>
            <li className="mt-auto cursor-pointer" onClick={handleLogoutClick}>
              <i className="fas fa-sign-out-alt mr-2 text-infl"></i> Sign Out
            </li>
          </ul>
        </nav>
        <main className="flex-1 ml-[16.67%]">{renderContent()}</main>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Confirm Logout"
        isRequiredButton={false}
      >
        <p>Are you sure you want to log out?</p>
        <div className="flex justify-end mt-4 space-x-2">
          <button
            onClick={() => setIsModalOpen(false)}
            className="px-4 py-2 rounded text-white border transform transition-transform duration-200 hover:scale-105"
            style={{
              background:
                "linear-gradient(111deg, #A50808 0%, rgba(174, 54, 54, 0) 100%)",
              borderColor: "#A50808",
            }}
          >
            Cancel
          </button>
          <button
            onClick={handleConfirmLogout}
            className="px-4 py-2 rounded text-white border transform transition-transform duration-200 hover:scale-105"
            style={{
              background:
                "linear-gradient(111deg, #08A1A5 0%, rgba(54, 83, 174, 0) 100%)",
              borderColor: "#08A1A5",
            }}
          >
            Confirm
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default InfluencerDashboard;
