import React from "react";

const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  isRequiredButton = true,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-background text-white p-6 rounded shadow-lg w-full max-w-md border border-infl">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">{title}</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
        </div>
        <div className="mt-4">{children}</div>
        <div className="mt-4 flex justify-end">
          {isRequiredButton && (
            <button
              onClick={onClose}
              className="px-4 py-2 rounded text-white w-full border transform transition-transform duration-200 hover:scale-105"
              style={{
                background:
                  "linear-gradient(111deg, #08A1A5 0%, rgba(54, 83, 174, 0) 100%)",
                borderColor: "#08A1A5",
              }}
            >
              Done
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
