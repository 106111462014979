import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useAuth } from "../../contexts/AuthContext";
import InfluencerCard from "./InfluencerCard";

const FavoritesComponent = () => {
  const { user } = useAuth();
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState(null);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/categories`,
        { withCredentials: true }
      );
      setCategories(response.data);
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    }
  };

  const getCategoryName = (uuid) => {
    const category = categories?.find((cat) => cat.uuid === uuid);
    return category ? category.name : "Unknown Category";
  };

  const fetchFavorites = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/favorites/${user.uuid}`
      );
      setFavorites(response.data);
    } catch (error) {
      console.error("Error fetching favorites:", error);
      setError("Failed to fetch favorites.");
    } finally {
      setLoading(false);
    }
  }, [user.uuid]);

  useEffect(() => {
    if (user) {
      fetchCategories();
      fetchFavorites();
    }
  }, [user, fetchFavorites]);

  const handleFavoriteChange = () => {
    fetchFavorites();
  };

  if (loading) {
    return <div>Loading favorites...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (favorites.length === 0) {
    return (
      <div className="flex flex-col h-full text-white px-16 py-8">
        <div>
          <h1 className="text-5xl">My Favorite</h1>
          <p className="mt-4">You didn't add any yet :(</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full text-white px-16 py-8">
      <div>
        <h1 className="text-5xl">My Favorite</h1>
        <p className="mt-4">Here are your favorite bloggers</p>
        <p className="mt-4 text-text">
          You have <span className="text-white">{favorites.length}</span>{" "}
          profiles that you like.
        </p>
      </div>
      <div className="flex mt-8">
        <div className="flex-grow w-full">
          <div className="flex flex-col gap-4">
            {favorites.map(({ platformProfile }) => (
              <InfluencerCard
                key={platformProfile.uuid}
                influencer={platformProfile}
                getCategoryName={getCategoryName}
                onFavoriteChange={handleFavoriteChange}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoritesComponent;
