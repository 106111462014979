import React from "react";
import LogoPlaceholder from "../../assets/logoPlaceholder.png";

const PlatformCard = ({ profile, onEdit, onDelete, getCategoryName }) => {
  const imageUrl =
    profile.images && profile.images.length > 0
      ? `${process.env.REACT_APP_API_URL}${profile.images[0].imageUrl}`
      : LogoPlaceholder;

  return (
    <div className="border border-infl p-8 rounded-lg shadow-lg w-full flex flex-row justify-between">
      <div className="mr-8">
        <img
          src={imageUrl}
          alt="Platform"
          className="max-w-xs max-h-42 object-cover"
        />
      </div>

      <div className="flex flex-col justify-between flex-grow">
        <div className="flex justify-between">
          <h2 className="text-2xl mb-4">
            {profile.platformName} -{" "}
            {profile.isApproved ? "Approved" : "Pending"}
          </h2>
          <button
            className="text-red-500 ml-auto"
            onClick={() => onDelete(profile.uuid)}
          >
            <i className="fas fa-trash-alt"></i>
          </button>
        </div>
        <div className="flex flex-row gap-4">
          <span className="bg-infl p-2 rounded-lg">
            {getCategoryName(profile.categoryUuid)}
          </span>
          <span className="bg-infl p-2 rounded-lg">
            {profile.followers.toLocaleString()} Followers
          </span>
          <span className="bg-infl p-2 rounded-lg">
            {profile.averageViews.toLocaleString()} Average Views
          </span>
          <span className="bg-infl p-2 rounded-lg">
            {profile.priceFrom.toLocaleString()}$ to{" "}
            {profile.priceTo.toLocaleString()}$
          </span>
        </div>
        <p className="text-base my-4">Description: {profile.bio}</p>
        <div className="flex justify-end">
          <button
            className="bg-infl rounded-xl p-2 w-1/5"
            onClick={() => onEdit(profile)}
          >
            Edit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlatformCard;
