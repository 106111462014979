import React from "react";

const Subscriptions = () => {
  return (
    <div className="flex flex-col h-full text-white px-16 py-8">
      <h1 className="text-5xl mb-4">Inflooms Pro</h1>
      <p className="mb-10">
        Take advantage of the full functionality of the platform with our tariff
        plan
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="bg-gradient-to-b from-[#00090C] to-[rgba(54,83,174,0.4)] rounded-lg p-6 border border-blue-600">
          <h2 className="text-2xl font-bold mb-2">Basic Subscribe</h2>
          <p className="text-xl mb-4">$9.99</p>
          <p className="mb-2">
            Take Advantage Of The Full Functionality Of The Platform With Our
            Tariff Plan
          </p>
          <p className="mb-2">
            Take Advantage Of The Full Functionality Of The Platform With Our
            Tariff Plan
          </p>
          <ul className="mb-4">
            <li className="flex items-center mb-2">
              <span className="text-blue-500 mr-2">🔍</span>
              Unlimited Searching
            </li>
            <li className="flex items-center mb-2">
              <span className="text-blue-500 mr-2">🔍</span>
              Unlimited Searching
            </li>
            <li className="flex items-center mb-2">
              <span className="text-blue-500 mr-2">🔍</span>
              Unlimited Searching
            </li>
          </ul>
          <button className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded">
            Get It
          </button>
        </div>
        <div className="bg-gradient-to-b from-[#00090C] to-[rgba(8,161,165,0.4)] rounded-lg p-6 border border-teal-600">
          <h2 className="text-2xl font-bold mb-2">Premium Subscribe</h2>
          <p className="text-xl mb-4">$999.99</p>
          <p className="mb-2">
            Take Advantage Of The Full Functionality Of The Platform With Our
            Tariff Plan
          </p>
          <p className="mb-2">
            Take Advantage Of The Full Functionality Of The Platform With Our
            Tariff Plan
          </p>
          <ul className="mb-4">
            <li className="flex items-center mb-2">
              <span className="text-teal-500 mr-2">🔍</span>
              Unlimited Searching
            </li>
            <li className="flex items-center mb-2">
              <span className="text-teal-500 mr-2">🔍</span>
              Unlimited Searching
            </li>
            <li className="flex items-center mb-2">
              <span className="text-teal-500 mr-2">🔍</span>
              Unlimited Searching
            </li>
          </ul>
          <button className="bg-teal-600 hover:bg-teal-700 text-white py-2 px-4 rounded">
            Get It
          </button>
        </div>
        <div className="bg-gradient-to-b from-[#00090C] to-[rgba(174,54,54,0.4)] rounded-lg p-6 border border-red-600">
          <h2 className="text-2xl font-bold mb-2">Luxury Subscribe</h2>
          <p className="text-xl mb-4">$999999.99</p>
          <p className="mb-2">
            Take Advantage Of The Full Functionality Of The Platform With Our
            Tariff Plan
          </p>
          <p className="mb-2">
            Take Advantage Of The Full Functionality Of The Platform With Our
            Tariff Plan
          </p>
          <ul className="mb-4">
            <li className="flex items-center mb-2">
              <span className="text-red-500 mr-2">🔍</span>
              Unlimited Searching
            </li>
            <li className="flex items-center mb-2">
              <span className="text-red-500 mr-2">🔍</span>
              Unlimited Searching
            </li>
            <li className="flex items-center mb-2">
              <span className="text-red-500 mr-2">🔍</span>
              Unlimited Searching
            </li>
          </ul>
          <button className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded">
            Get It
          </button>
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;
