import React from "react";
import Logo from "../assets/Logo.webp";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth();

  const handleNavigate = (to) => {
    if (location.pathname !== "/") {
      navigate("/");
    }

    const section = document.getElementById(to);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleLogout = () => {
    logout();
    navigate("/"); // Optional: redirect to homepage on logout
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSignup = () => {
    navigate("/signup");
  };

  return (
    <nav className="fixed w-full h-[80px] flex justify-between items-center z-50 text-white py-4 px-4 bg-[rgba(0,0,0,0.2)] backdrop-blur-lg">
      <div className="container mx-auto flex justify-between items-center text-text">
        {/* Logo */}
        <div className="text-lg font-bold">
          <img
            src={Logo}
            alt="Inflooms Influence Marketing Agency Logo"
            className="h-8"
          />
        </div>

        {/* Navigation Links */}
        <ul className="hidden md:flex justify-center items-center gap-4 text-lg">
          {["home", "about", "services", "contact", "for-influencers"].map(
            (section) => (
              <li key={section} className="cursor-pointer">
                <button onClick={() => handleNavigate(section)} className="p-2">
                  {section.charAt(0).toUpperCase() + section.slice(1)}
                </button>
              </li>
            )
          )}
        </ul>

        {/* Login, Signup, and Logout Buttons */}
        {isAuthenticated ? (
          <button onClick={handleLogout}>Logout</button>
        ) : (
          <div className="hidden md:flex gap-4">
            <button
              onClick={handleLogin}
              className="p-2 bg-transparent border border-white rounded-md hover:bg-white hover:text-black"
            >
              Login
            </button>
            <button
              onClick={handleSignup}
              className="p-2 bg-white text-black rounded-md hover:bg-gray-300"
            >
              Signup
            </button>
          </div>
        )}

        {/* Mobile Menu (if needed) */}
      </div>
    </nav>
  );
};

export default Navbar;
