import React from "react";
import Logo from "../assets/Logo.webp";

const DashboardNav = () => {
  return (
    <div className="flex items-center justify-between bg-background text-text p-5">
      <div className="flex items-center">
        <img src={Logo} alt="Logo" className="h-8 mr-2" />
      </div>
      <div className="flex items-center space-x-4">
        <button className="relative">
          <i className="fas fa-bell"></i>
          <span className="absolute top-0 right-0 h-2 w-2 bg-red-600 rounded-full"></span>
        </button>
        <select className="bg-gray-700 text-white p-1 rounded">
          <option value="en">EN</option>
          <option value="es">ES</option>
          <option value="fr">FR</option>
        </select>
      </div>
    </div>
  );
};

export default DashboardNav;
