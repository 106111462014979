import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import styles from "../styles/SignupForm.module.css";
import axios from "axios";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useNavigate } from "react-router-dom";

const SignupForm = () => {
  const navigate = useNavigate();
  Yup.addMethod(Yup.string, "phone", function (errorMessage) {
    return this.test("test-phone", errorMessage, function (value) {
      const { path, createError } = this;
      return (
        isValidPhoneNumber(value) ||
        createError({ path, message: errorMessage })
      );
    });
  });
  const validationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    phoneNumber: Yup.string()
      .phone("Invalid phone number")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    userName: Yup.string().required("Required"),
    password: Yup.string().required("Required").min(8, "Password is too short"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
    agreeToTerms: Yup.bool().oneOf(
      [true],
      "You must accept the terms and conditions"
    ),
    role: Yup.string().required("Role is required"),
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "+41",
    email: "",
    userName: "",
    password: "",
    confirmPassword: "",
    agreeToTerms: false,
    role: "Client",
  };
  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/register`, values)
      .then((response) => {
        navigate("/login");
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          // Assuming the server specifies which field is in error
          if (error.response.data.errors.username) {
            setFieldError("userName", "Username already exists");
          }
          if (error.response.data.errors.email) {
            setFieldError("email", "Email already exists");
          }
        } else {
          alert("Registration failed. Please try again later.");
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form className="space-y-6 bg-[#0E1117] p-8 rounded-lg shadow-xl">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <Field
                name="firstName"
                type="text"
                placeholder="First Name*"
                className="input"
              />
              <ErrorMessage
                name="firstName"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div>
              <Field
                name="lastName"
                type="text"
                placeholder="Last Name*"
                className="input"
              />
              <ErrorMessage
                name="lastName"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>
          <div>
            <PhoneInput
              international
              defaultCountry="CH"
              placeholder="Enter phone number"
              value={formik.values.phoneNumber}
              onChange={(value) => formik.setFieldValue("phoneNumber", value)}
              className={`input ${styles.phoneInput} w-full`}
            />
            <ErrorMessage
              name="phoneNumber"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div>
            <Field
              name="email"
              type="email"
              placeholder="Email Address*"
              className="input"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div>
            <Field
              name="userName"
              type="text"
              placeholder="Username*"
              className="input"
            />
            <ErrorMessage
              name="userName"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div>
            <Field
              name="password"
              type="password"
              placeholder="Password*"
              className="input"
            />
            <ErrorMessage
              name="password"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div>
            <Field
              name="confirmPassword"
              type="password"
              placeholder="Confirm Password*"
              className="input"
            />
            <ErrorMessage
              name="confirmPassword"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className="flex items-center">
            <Field type="checkbox" name="agreeToTerms" className="mr-2" />
            <label className="text-white text-sm">
              I agree to the Terms and Conditions
            </label>
            <ErrorMessage
              name="agreeToTerms"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className="mb-4 flex items-center">
            <span className="text-white text-sm mr-3">Role:</span>
            <div role="group" aria-labelledby="role-group" className="flex">
              <label className="flex items-center mr-4">
                <Field
                  type="radio"
                  name="role"
                  value="Client"
                  className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                />
                <span className="ml-2 text-white text-sm">Advertiser</span>
              </label>
              <label className="flex items-center">
                <Field
                  type="radio"
                  name="role"
                  value="Influencer"
                  className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                />
                <span className="ml-2 text-white text-sm">Influencer</span>
              </label>
            </div>
          </div>

          <button
            type="submit"
            className="inflooms-btn inflooms-btn-green w-full rounded-xl"
            style={{
              background:
                "linear-gradient(111deg, #08A1A5 0%, rgba(54, 83, 174, 0) 100%)",
              borderColor: "#08A1A5",
            }}
          >
            Sign Up
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default SignupForm;
