import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import DashboardNav from "../DashboardNav";
import ManageSettings from "../Shared/ManageSettings";
import PasswordChangeForm from "../Shared/PasswordChangeForm";
import AddBlogger from "./AddBlogger";
import axios from "axios";
import BloggerList from "./BloggerList";
import Notifications from "./Notifications";

const AdminDashboard = () => {
  const { user, logout } = useAuth();
  const [selectedMenu, setSelectedMenu] = useState("add");
  const [categories, setCategories] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [platformProfiles, setPlatformProfiles] = useState([]);
  const [, setError] = useState(null);
  const [editingProfile, setEditingProfile] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const handleLogout = async () => {
    await logout();
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/categories`,
        { withCredentials: true }
      );
      setCategories(response.data);
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    }
  };

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/notifications/`
      );
      setNotifications(response.data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const fetchPlatforms = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/platforms`,
        { withCredentials: true }
      );
      setPlatforms(response.data);
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    }
  };

  const fetchPlatformProfiles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/profiles/approved/user`,
        { withCredentials: true }
      );
      setPlatformProfiles(response.data);
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchPlatforms();
    fetchPlatformProfiles();
    fetchNotifications();
  }, []);

  const handleCancel = () => {
    setSelectedMenu("bloggerList");
  };

  const handleAddPlatform = () => {
    setSelectedMenu("add");
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      const payload = {
        platformName: values.platformName,
        platformUrl: values.platformUrl,
        platformEmail: values.platformEmail,
        platformGeo: values.platformGeo,
        categoryUuid: values.category,
        followers: values.followers,
        averageViews: values.averageViews,
        averageAge: values.averageAge,
        priceFrom: values.priceFrom,
        priceTo: values.priceTo,
        bio: values.bio,
        platformUuid: values.platform,
      };

      let response;
      if (values.uuid) {
        response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/profiles/${values.uuid}`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/profiles/`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }

      const createdProfile = response.data;

      // Step 2: Upload the image if there is one
      if (values.image) {
        const formData = new FormData();
        formData.append("image", values.image);
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/profiles/upload/${createdProfile.uuid}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      resetForm();
      setEditingProfile(null);
      fetchPlatformProfiles();
    } catch (error) {
      setError(error.response ? error.response.data.message : error.message);
    }
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case "bloggerList":
        return (
          <div className="bg-background h-full w-full">
            <BloggerList
              onAddPlatform={handleAddPlatform}
              categories={categories}
              platforms={platforms}
              platformProfiles={platformProfiles}
              setSelectedMenu={setSelectedMenu}
              onSubmit={handleFormSubmit}
              refreshProfiles={fetchPlatformProfiles}
              editingProfile={editingProfile}
              setEditingProfile={setEditingProfile}
            />
          </div>
        );
      case "add":
        return (
          <div className="bg-background h-full w-full">
            <AddBlogger
              categories={categories}
              platforms={platforms}
              onCancel={handleCancel}
              onSubmit={handleFormSubmit}
            />
          </div>
        );
      case "notifications":
        return (
          <div className="bg-background h-full w-full">
            <Notifications
              categories={categories}
              notifications={notifications}
              fetchNotifications={fetchNotifications}
            />
          </div>
        );
      case "security":
        return (
          <div className="bg-background h-full w-full">
            <PasswordChangeForm />
          </div>
        );
      case "manage":
        return (
          <div className="bg-background h-full w-full">
            <ManageSettings />
          </div>
        );
      default:
        return <div>Home Content</div>;
    }
  };

  if (!user) {
    return <div>Loading or not logged in...</div>;
  }

  return (
    <div className="flex flex-col h-screen">
      <div className="fixed top-0 left-0 right-0 z-10">
        <DashboardNav />
      </div>
      <div className="flex flex-1 pt-16">
        <nav
          className="fixed top-16 left-0 bottom-0 w-1/6 text-white h-full "
          style={{ background: "linear-gradient(to bottom, #00090C, #3653AE)" }}
        >
          <ul className="space-y-4 p-4">
            <li
              className={`cursor-pointer ${
                selectedMenu === "bloggerList" && "text-primary"
              }`}
              onClick={() => setSelectedMenu("bloggerList")}
            >
              <i className="fas fa-search mr-2"></i> List of Bloggers
            </li>
            <li
              className={`cursor-pointer ${
                selectedMenu === "add" && "text-primary"
              }`}
              onClick={() => setSelectedMenu("add")}
            >
              <i className="fas fa-search mr-2"></i> Add Bloggers
            </li>
            <li
              className={`cursor-pointer ${
                selectedMenu === "notifications" && "text-primary"
              }`}
              onClick={() => setSelectedMenu("notifications")}
            >
              <i className="fas fa-plus-circle mr-2"></i> Notifications
            </li>
            <li className="mt-6">
              <span className="text-sm block mb-2 text-primary">
                Salut {user.userName}
              </span>
            </li>

            <li
              className={`cursor-pointer ${
                selectedMenu === "manage" && "text-primary"
              }`}
              onClick={() => setSelectedMenu("manage")}
            >
              <i className="fas fa-cog mr-2"></i> Manage Settings
            </li>
            <li
              className={`cursor-pointer ${
                selectedMenu === "security" && "text-primary"
              }`}
              onClick={() => setSelectedMenu("security")}
            >
              <i className="fas fa-lock mr-2"></i> Safety
            </li>
            <li
              className="mt-auto cursor-pointer text-red-500"
              onClick={handleLogout}
            >
              <i className="fas fa-sign-out-alt mr-2"></i> Sign Out
            </li>
          </ul>
        </nav>
        <main className="flex-1 ml-[16.67%]">{renderContent()}</main>
      </div>
    </div>
  );
};

export default AdminDashboard;
