import React, { useState } from "react";
import Logo from "../../assets/Logo-color.png";
import Av1 from "../../assets/av-1.png";
import Av2 from "../../assets/av-2.png";
import Av3 from "../../assets/av-3.png";
import Av4 from "../../assets/av-4.png";
import Av5 from "../../assets/av-5.png";
import Av6 from "../../assets/av-6.png";
import Av7 from "../../assets/av-7.png";
import Av8 from "../../assets/av-8.png";
import CollaborationForm from "./CollaborationForm";

const avatars = [Av1, Av2, Av3, Av4, Av5, Av6, Av7, Av8];

const JoinForm = () => {
  const [showCollaborationForm, setShowCollaborationForm] = useState(false);

  const onJoinClick = () => {
    setShowCollaborationForm(true);
  };

  return !showCollaborationForm ? (
    <div className="flex flex-col h-full text-white px-16 pb-8">
      <h1 className="text-5xl">Join Inflooms</h1>
      <p className="mt-10">Don't know how to find advertisers?</p>
      <p className="">Don't want to track collaborations yourself?</p>
      <p className="mb-10">Advertisers don't pay on time?</p>

      <div className="bg-gradient-to-b from-[#00090C] to-[rgba(8,161,165,0.4)] text-white p-10 rounded-lg shadow-lg flex flex-col items-center space-y-8 w-1/2 border border-infl">
        <h2 className="text-3xl font-bold">With Us You Grow Up</h2>
        <img src={Logo} alt="Inflooms Logo" className="w-48" />
        <p className="text-center text-lg max-w-2xl">
          We always offer our influencers a variety of projects for cooperation,
          with the opportunity to choose a customer. Statistics and reporting,
          negotiations with the customer and the formation of technical
          specifications are carried out by us, without distracting you from
          your favorite process.
        </p>
        <div className="flex space-x-2">
          {avatars.map((avatar, index) => (
            <div
              key={index}
              className="w-16 h-16 bg-gray-500 rounded-full overflow-hidden"
            >
              <img
                src={avatar}
                alt={`Profile ${index + 1}`}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
        <div className="grid grid-cols-2 gap-4 text-center bg-white bg-opacity-10 p-4 rounded-lg">
          <div>
            <p className="text-lg font-semibold">Number of Clients</p>
            <p className="text-2xl font-bold">12345678</p>
          </div>
          <div>
            <p className="text-lg font-semibold">Total Number of Promotion</p>
            <p className="text-2xl font-bold">12345678</p>
          </div>
          <div>
            <p className="text-lg font-semibold">Total Number of Benefits</p>
            <p className="text-2xl font-bold">12345678</p>
          </div>
        </div>
        <button
          className="inflooms-btn inflooms-btn-green rounded-xl w-3/4"
          style={{
            background:
              "linear-gradient(111deg, #08A1A5 0%, rgba(54, 83, 174, 0) 100%)",
            borderColor: "#08A1A5",
          }}
          onClick={onJoinClick}
        >
          Join Inflooms
        </button>
      </div>
    </div>
  ) : (
    <CollaborationForm setShowCollaborationForm={setShowCollaborationForm} />
  );
};

export default JoinForm;
