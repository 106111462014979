import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const Filters = ({ onFilterChange, categories }) => {
  const [platformOptions, setPlatformOptions] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchPlatforms = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/platforms`,
          { withCredentials: true }
        );
        setPlatformOptions(response.data);
      } catch (error) {
        setError(error.response?.data?.message || error.message);
      }
    };

    fetchPlatforms();
  }, []);

  const validationSchema = Yup.object({
    category: Yup.string().required("Category is required"),
    geo: Yup.string().required("Geographic location is required"),
    age: Yup.string()
      .oneOf(["0-12", "13-17", "18-35", "36-50", "51+"], "Invalid age group")
      .required("Average age group is required"),
    followers: Yup.string().required("Number of followers is required"),
    totalViews: Yup.number().required("Total views are required").min(0),
    priceFrom: Yup.number().required("Starting rate is required").min(0),
    priceTo: Yup.number()
      .required("Ending rate is required")
      .min(
        Yup.ref("priceFrom"),
        "Ending rate must be greater than starting rate"
      ),
    platformUuids: Yup.array().min(1, "At least one platform is required"),
  });

  return (
    <div className="filters flex flex-col gap-4 w-full">
      {error && <p className="text-red-500">{error}</p>}
      <Formik
        initialValues={{
          category: "",
          geo: "",
          age: "",
          followers: "",
          totalViews: 0,
          priceFrom: 50,
          priceTo: 5000,
          platformUuids: [],
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onFilterChange(values);
        }}
      >
        {({ setFieldValue, values }) => (
          <Form className="grid grid-cols-2 gap-4">
            <div className="col-span-2">
              <label
                htmlFor="category"
                className="block text-sm font-medium mb-1"
              >
                Category
              </label>
              <Field
                as="select"
                name="category"
                className="w-full bg-gray-800 text-white p-2 rounded"
              >
                <option value="">Find influencers by category...</option>
                {categories.map((cat) => (
                  <option
                    key={cat.uuid}
                    value={cat.uuid}
                    className="bg-background text-primary"
                  >
                    {cat.name}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="category"
                component="div"
                className="text-red-500"
              />
            </div>

            <div className="col-span-2">
              <label htmlFor="geo" className="block text-sm font-medium mb-1">
                Geo
              </label>
              <Field
                as="select"
                name="geo"
                className="w-full bg-gray-800 text-white p-2 rounded"
              >
                <option value="">Choose a Geo</option>
                <option className="bg-background text-primary" value="Europe">
                  Europe
                </option>
                <option
                  className="bg-background text-primary"
                  value="Latin America"
                >
                  Latin America
                </option>
                <option
                  className="bg-background text-primary"
                  value="North America"
                >
                  North America
                </option>
                <option
                  className="bg-background text-primary"
                  value="Middle East"
                >
                  Middle East
                </option>
                <option className="bg-background text-primary" value="Africa">
                  Africa
                </option>
                <option
                  className="bg-background text-primary"
                  value="Asia/Pacific"
                >
                  Asia/Pacific
                </option>
              </Field>
              <ErrorMessage
                name="geo"
                component="div"
                className="text-red-500"
              />
            </div>

            <div className="col-span-2">
              <label htmlFor="age" className="block text-sm font-medium mb-1">
                Average Age
              </label>
              <Field
                as="select"
                name="age"
                className="w-full bg-gray-800 text-white p-2 rounded"
              >
                <option value="">Choose an Age</option>
                <option className="bg-background text-primary" value="0-12">
                  0-12
                </option>
                <option className="bg-background text-primary" value="13-17">
                  13-17
                </option>
                <option className="bg-background text-primary" value="18-35">
                  18-35
                </option>
                <option className="bg-background text-primary" value="36-50">
                  36-50
                </option>
                <option className="bg-background text-primary" value="51+">
                  51+
                </option>
              </Field>
              <ErrorMessage
                name="age"
                component="div"
                className="text-red-500"
              />
            </div>

            <div className="col-span-2">
              <label
                htmlFor="followers"
                className="block text-sm font-medium mb-1"
              >
                Followers
              </label>
              <Field
                as="select"
                name="followers"
                className="w-full bg-gray-800 text-white p-2 rounded"
              >
                <option value="">Find influencers by followers...</option>
                <option className="bg-background text-primary" value="0-1000">
                  0-1,000
                </option>
                <option
                  className="bg-background text-primary"
                  value="1001-10000"
                >
                  1,001-10,000
                </option>
                <option
                  className="bg-background text-primary"
                  value="10001-50000"
                >
                  10,001-50,000
                </option>
                <option
                  className="bg-background text-primary"
                  value="50001-100000"
                >
                  50,001-100,000
                </option>
                <option
                  className="bg-background text-primary"
                  value="100001-500000"
                >
                  100,001-500,000
                </option>
                <option
                  className="bg-background text-primary"
                  value="500001-1000000"
                >
                  500,001-1,000,000
                </option>
                <option className="bg-background text-primary" value="1000001">
                  1,000,001+
                </option>
              </Field>
              <ErrorMessage
                name="followers"
                component="div"
                className="text-red-500"
              />
            </div>

            <div className="col-span-2">
              <label
                htmlFor="totalViews"
                className="block text-sm font-medium mb-1"
              >
                Total Views
              </label>
              <Field name="totalViews">
                {({ field, form }) => (
                  <>
                    <input
                      type="range"
                      id="totalViews"
                      {...field}
                      min="0"
                      max="50000000"
                      step="1000"
                      className="w-full"
                      onChange={(e) =>
                        form.setFieldValue(field.name, e.target.value)
                      }
                    />
                    <div className="flex justify-between text-xs mt-1">
                      <span>0</span>
                      <span>50.000.000</span>
                    </div>
                    <input
                      type="text"
                      value={Number(field.value).toLocaleString()}
                      onChange={(e) => {
                        const rawValue = e.target.value.replace(/,/g, "");
                        const value = Math.min(
                          Math.max(parseInt(rawValue, 10) || 0, 0),
                          50000000
                        );
                        form.setFieldValue(field.name, value);
                      }}
                      className="w-full bg-gray-800 text-center text-white p-2 mt-2 rounded"
                    />
                  </>
                )}
              </Field>
              <ErrorMessage
                name="totalViews"
                component="div"
                className="text-red-500"
              />
            </div>

            <div className="col-span-1">
              <label
                htmlFor="priceFrom"
                className="block text-sm font-medium mb-1"
              >
                Starting Rate
              </label>
              <Field
                type="number"
                name="priceFrom"
                className="w-full bg-gray-800 text-white p-2 rounded"
              />
              <ErrorMessage
                name="priceFrom"
                component="div"
                className="text-red-500"
              />
            </div>

            <div className="col-span-1">
              <label
                htmlFor="priceTo"
                className="block text-sm font-medium mb-1"
              >
                Ending Rate
              </label>
              <Field
                type="number"
                name="priceTo"
                className="w-full bg-gray-800 text-white p-2 rounded"
              />
              <ErrorMessage
                name="priceTo"
                component="div"
                className="text-red-500"
              />
            </div>

            <div className="col-span-2">
              <label className="block text-sm font-medium mb-1">
                Platforms
              </label>
              <div className="flex flex-wrap gap-4">
                {platformOptions.map((platform) => (
                  <label
                    key={platform.uuid}
                    className="flex items-center gap-2"
                  >
                    <Field
                      type="checkbox"
                      name="platformUuids"
                      value={platform.uuid}
                      checked={values.platformUuids.includes(platform.uuid)}
                      onChange={() => {
                        const set = new Set(values.platformUuids);
                        set.has(platform.uuid)
                          ? set.delete(platform.uuid)
                          : set.add(platform.uuid);
                        setFieldValue("platformUuids", Array.from(set));
                      }}
                      className="form-checkbox"
                    />
                    {platform.name}
                  </label>
                ))}
              </div>
              <ErrorMessage
                name="platformUuids"
                component="div"
                className="text-red-500"
              />
            </div>

            <div className="col-span-2 flex justify-end mt-4">
              <button
                type="submit"
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Search
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Filters;
