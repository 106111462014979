import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import DashboardNav from "../DashboardNav";
import ManageSettings from "../Shared/ManageSettings";
import PasswordChangeForm from "../Shared/PasswordChangeForm";
import SearchComponent from "./SearchComponent";
import FavoritesComponent from "./FavoritesComponent";
import Selections from "./Selections";
import Subscriptions from "./Subscriptions";

const ClientDashboard = () => {
  const { user, logout } = useAuth();
  const [selectedMenu, setSelectedMenu] = useState("search");

  const handleLogout = async () => {
    await logout();
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case "search":
        return (
          <div className="bg-background h-full w-full">
            <SearchComponent />
          </div>
        );
      case "selection":
        return (
          <div className="bg-background h-full w-full">
            <Selections />
          </div>
        );
      case "favorite":
        return (
          <div className="bg-background h-full w-full">
            <FavoritesComponent />
          </div>
        );
      case "pro":
        return (
          <div className="bg-background h-full w-full">
            <Subscriptions />
          </div>
        );
      case "manage":
        return (
          <div className="bg-background h-full w-full">
            <ManageSettings />
          </div>
        );
      case "security":
        return (
          <div className="bg-background h-full w-full">
            <PasswordChangeForm />
          </div>
        );
      default:
        return <div className="bg-background h-full w-full">Home Content</div>;
    }
  };

  if (!user) {
    return <div>Loading or not logged in...</div>;
  }

  return (
    <div className="flex flex-col h-screen overflow-y-hidden">
      <div className="fixed top-0 left-0 right-0 z-10">
        <DashboardNav />
      </div>
      <div className="flex flex-1 pt-16">
        <nav
          className="fixed top-16 left-0 bottom-0 w-1/6 text-white h-full"
          style={{ background: "linear-gradient(to bottom, #00090C, #3653AE)" }}
        >
          <ul className="space-y-6 border-y border-primary py-6 px-5">
            <li
              className={`cursor-pointer ${
                selectedMenu === "search" && "text-primary"
              }`}
              onClick={() => setSelectedMenu("search")}
            >
              <i className="fas fa-search mr-2 text-primary"></i> Searching
              Bloggers
            </li>
            <li
              className={`cursor-pointer ${
                selectedMenu === "selection" && "text-primary"
              }`}
              onClick={() => setSelectedMenu("selection")}
            >
              <i className="fas fa-plus-circle mr-2 text-primary"></i> Add
              Selections
            </li>
            <li
              className={`cursor-pointer ${
                selectedMenu === "favorite" && "text-primary"
              }`}
              onClick={() => setSelectedMenu("favorite")}
            >
              <i className="fas fa-heart mr-2 text-primary"></i> My Favorite
            </li>
            <li className="mt-6">
              <span className="text-sm block mb-2 text-primary">
                {user.email}
              </span>
            </li>
            <li
              className={`cursor-pointer ${
                selectedMenu === "pro" && "text-primary"
              }`}
              onClick={() => setSelectedMenu("pro")}
            >
              <i className="fas fa-gem mr-2 text-primary"></i> Inflooms PRO
            </li>
            <li
              className={`cursor-pointer ${
                selectedMenu === "manage" && "text-primary"
              }`}
              onClick={() => setSelectedMenu("manage")}
            >
              <i className="fas fa-cog mr-2 text-primary"></i> Manage Settings
            </li>
            <li
              className={`cursor-pointer ${
                selectedMenu === "security" && "text-primary"
              }`}
              onClick={() => setSelectedMenu("security")}
            >
              <i className="fas fa-lock mr-2 text-primary"></i> Safety
            </li>
            <li
              className="mt-auto cursor-pointer text-red-500"
              onClick={handleLogout}
            >
              <i className="fas fa-sign-out-alt mr-2 text-primary"></i> Sign Out
            </li>
          </ul>
        </nav>
        <main className="flex-1 ml-[16.67%]">{renderContent()}</main>
      </div>
    </div>
  );
};

export default ClientDashboard;
