import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Modal from "./Shared/Modal";

const PasswordResetRequestForm = () => {
  const [message, setMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format").required("Required"),
  });

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setMessage("");
    axios
      .post(`${process.env.REACT_APP_API_URL}/forgot-password`, values)
      .then((response) => {
        setMessage("Password reset link has been sent to your email.");
        setSubmitting(false);
        resetForm();
        setIsSuccessModalOpen(true);
      })
      .catch((error) => {
        let errorMsg = "An unexpected error occurred. Please try again later.";
        if (error.response && error.response.status === 404) {
          errorMsg = "User not found with the provided email.";
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMsg = error.response.data.message;
        }
        setErrorMessage(errorMsg);
        setSubmitting(false);
        setIsErrorModalOpen(true); // Open error modal
      });
  };

  return (
    <div className="flex justify-center items-center h-screen bg-background">
      <div className="w-full max-w-xs">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="bg-inherit shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-bold mb-2 text-white"
                >
                  E-mail Address:
                </label>
                <Field
                  type="email"
                  name="email"
                  className="input"
                  placeholder="Write your mail"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-xs italic"
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="inflooms-btn w-full rounded-xl"
                  disabled={isSubmitting}
                >
                  Send Password Reset Link
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <Modal
          isOpen={isSuccessModalOpen}
          onClose={() => setIsSuccessModalOpen(false)}
          title="Success"
        >
          <p>{message}</p>
        </Modal>
        <Modal
          isOpen={isErrorModalOpen}
          onClose={() => setIsErrorModalOpen(false)}
          title="Error"
        >
          <p>{errorMessage}</p>
        </Modal>
      </div>
    </div>
  );
};

export default PasswordResetRequestForm;
