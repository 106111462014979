import React from "react";
import axios from "axios";
import AddPlatform from "../InfluencerDashboard/AddPlatform";
import PlatformCard from "../InfluencerDashboard/PlatformCard";
import AddBlogger from "./AddBlogger";

const BloggerList = ({
  categories,
  platforms,
  platformProfiles,
  setSelectedMenu,
  onSubmit,
  refreshProfiles,
  editingProfile,
  setEditingProfile,
}) => {
  const handleEdit = (profile) => {
    setEditingProfile({
      ...profile,
      category: profile.categoryUuid,
      platform: profile.platformUuid,
    });
  };

  const handleCancel = () => {
    setEditingProfile(null);
    setSelectedMenu("bloggerList");
  };

  const handleDelete = async (uuid) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/profiles/${uuid}`,
        {
          withCredentials: true,
        }
      );
      refreshProfiles();
    } catch (error) {
      console.error(error.response?.data?.message || error.message);
    }
  };

  const getCategoryName = (uuid) => {
    const category = categories.find((cat) => cat.uuid === uuid);
    return category ? category.name : "Unknown Category";
  };

  return (
    <div className="flex flex-col h-full text-white px-16 pb-8">
      {editingProfile ? (
        <AddBlogger
          categories={categories}
          platforms={platforms}
          initialValues={editingProfile}
          onSubmit={onSubmit}
          onCancel={handleCancel}
        />
      ) : (
        <>
          <div className="">
            <h1 className="text-5xl">All Platforms</h1>
          </div>
          {platformProfiles.length > 0 ? (
            <div className="flex flex-col gap-4">
              {platformProfiles.map((profile) => (
                <PlatformCard
                  key={profile.uuid}
                  profile={profile}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  getCategoryName={getCategoryName}
                />
              ))}
            </div>
          ) : (
            <div className="border border-infl p-8 rounded-lg shadow-lg w-3/4 max-w-lg text-center">
              <h2 className="text-3xl mb-4">
                You don't have any platforms yet
              </h2>
              <p className="text-base mb-6">
                Add your platforms from social networks in order to accept
                applications from advertisers and start monetizing social
                networks.
              </p>
              <button
                className="inflooms-btn inflooms-btn-green w-full rounded-xl"
                style={{
                  background:
                    "linear-gradient(111deg, #08A1A5 0%, rgba(54, 83, 174, 0) 100%)",
                  borderColor: "#08A1A5",
                }}
                onClick={() => setSelectedMenu("add")}
              >
                Add
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BloggerList;
