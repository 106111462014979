import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../contexts/AuthContext";
import Modal from "./Modal";

const validationSchema = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  userName: Yup.string().required("Required"),
  phoneNumber: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  receiveEmailNotifications: Yup.boolean(),
});

const ManageSettings = ({ setShowSettingsForm, relatedTo = "client" }) => {
  let styleColor;
  let buttonStyle;
  let hoverStyle;
  if (relatedTo === "client") {
    styleColor = "primary";
    buttonStyle = {
      background:
        "linear-gradient(111deg, #3653AE 0%, rgba(174, 54, 54, 0) 100%)",
      borderColor: "#3653AE",
    };
    hoverStyle = "";
  } else if (relatedTo === "influencer") {
    styleColor = "infl";
    buttonStyle = {
      background:
        "linear-gradient(111deg, #A50808 0%, rgba(174, 54, 54, 0) 100%)",
      borderColor: "#A50808",
    };
    hoverStyle = "inflooms-btn-green";
  }

  const { user, updateUser } = useAuth();
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    phoneNumber: "",
    email: "",
    receiveEmailNotifications: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (user) {
      setInitialValues({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        userName: user.userName || "",
        phoneNumber: user.phoneNumber || "",
        email: user.email || "",
        receiveEmailNotifications: user.receiveEmailNotifications || false,
      });
    }
  }, [user]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await updateUser(values);
      setIsModalOpen(true); // Show the modal on successful update
    } catch (error) {
      console.error("Failed to update user:", error);
    } finally {
      setSubmitting(false); // Set submitting state to false after submission
    }
  };

  return (
    <div className={`flex flex-col h-full text-white px-16 py-8`}>
      <h1 className="text-5xl mb-4">Manage Settings</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="space-y-8">
            <div className="grid grid-cols-2 gap-8">
              <div>
                <label className="block mb-2 text-lg">First Name</label>
                <Field
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  className={`w-full p-2 bg-inherit border border-${styleColor} rounded`}
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-red-500 mt-1"
                />
              </div>
              <div>
                <label className="block mb-2 text-lg">Last Name</label>
                <Field
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  className={`w-full p-2 bg-inherit border border-${styleColor} rounded`}
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-red-500 mt-1"
                />
              </div>
              <div>
                <label className="block mb-2 text-lg">Username</label>
                <Field
                  type="text"
                  name="userName"
                  placeholder="Username"
                  className={`w-full p-2 bg-inherit border border-${styleColor} rounded`}
                />
                <ErrorMessage
                  name="userName"
                  component="div"
                  className="text-red-500 mt-1"
                />
              </div>
              <div>
                <label className="block mb-2 text-lg">Phone Number</label>
                <Field
                  type="text"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  className={`w-full p-2 bg-inherit border border-${styleColor} rounded`}
                />
                <ErrorMessage
                  name="phoneNumber"
                  component="div"
                  className="text-red-500 mt-1"
                />
              </div>
              <div>
                <label className="block mb-2 text-lg">E-Mail Address</label>
                <Field
                  type="email"
                  name="email"
                  placeholder="Email"
                  className={`w-full p-2 bg-inherit border border-${styleColor} rounded`}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 mt-1"
                />
              </div>
              <div className="col-span-2">
                <label className="block mb-2 text-lg">
                  <Field
                    type="checkbox"
                    name="receiveEmailNotifications"
                    className="mr-2"
                  />
                  Receive email notifications
                </label>
              </div>
              <div className="col-span-1 text-center">
                <button
                  type="submit"
                  className={`inflooms-btn ${hoverStyle} rounded-xl px-8 py-2 w-3/4`}
                  disabled={isSubmitting}
                  style={buttonStyle}
                >
                  Save
                </button>
              </div>
              <div className="col-span-1 text-center">
                <button
                  type="button"
                  className="inflooms-btn inflooms-btn-red rounded-xl px-8 py-2 w-3/4"
                  onClick={() => setShowSettingsForm(false)}
                  style={{
                    background:
                      "linear-gradient(111deg, #A50808 0%, rgba(174, 54, 54, 0) 100%)",
                    borderColor: "#A50808",
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Settings Updated"
        role={user?.role}
      >
        <p>Your settings have been updated successfully!</p>
      </Modal>
    </div>
  );
};

export default ManageSettings;
