import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegistrationPage";
import InfluencePage from "./pages/InfluencePage";
import BrandPage from "./pages/BrandPage";
import HomePage from "./pages/HomePage";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import axios from "axios";
import ProtectedRoute from "./components/ProtectedRoute";
import ClientDashboard from "./components/ClientDashboard/ClientDashboard";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import InfluencerDashboard from "./components/InfluencerDashboard/InfluencerDashboard";
import PasswordResetRequestForm from "./components/PasswordResetRequestForm";
import PasswordResetForm from "./components/PasswordResetForm";
import Navbar from "./components/Navbar";

axios.defaults.withCredentials = true;

const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </AuthProvider>
  );
};

const AppContent = () => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {!user && <Navbar />}
      <Routes>
        <Route
          path="/"
          element={
            !user ? (
              <HomePage />
            ) : (
              <Navigate to={`/${user.role.toLowerCase()}-dashboard`} />
            )
          }
        />
        <Route
          path="/login"
          element={
            !user ? (
              <LoginPage />
            ) : (
              <Navigate to={`/${user.role.toLowerCase()}-dashboard`} />
            )
          }
        />
        <Route
          path="/signup"
          element={
            !user ? (
              <RegistrationPage />
            ) : (
              <Navigate to={`/${user.role.toLowerCase()}-dashboard`} />
            )
          }
        />
        <Route
          path="/brand-marketing"
          element={
            !user ? (
              <BrandPage />
            ) : (
              <Navigate to={`/${user.role.toLowerCase()}-dashboard`} />
            )
          }
        />
        <Route
          path="/influence-marketing"
          element={
            !user ? (
              <InfluencePage />
            ) : (
              <Navigate to={`/${user.role.toLowerCase()}-dashboard`} />
            )
          }
        />
        <Route
          path="/client-dashboard"
          element={<ProtectedRoute requiredRole="Client" />}
        >
          <Route path="" element={<ClientDashboard />} />
        </Route>
        <Route
          path="/influencer-dashboard"
          element={<ProtectedRoute requiredRole="Influencer" />}
        >
          <Route path="" element={<InfluencerDashboard />} />
        </Route>
        <Route
          path="/admin-dashboard"
          element={<ProtectedRoute requiredRole="Admin" />}
        >
          <Route path="" element={<AdminDashboard />} />
        </Route>
        <Route path="/forgot-password" element={<PasswordResetRequestForm />} />
        <Route path="/reset-password/:token" element={<PasswordResetForm />} />
        <Route
          path="*"
          element={
            <Navigate
              to={user ? `/${user.role.toLowerCase()}-dashboard` : "/"}
            />
          }
        />
      </Routes>
    </>
  );
};

export default App;
