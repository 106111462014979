import React from "react";
import Logo from "../assets/Logo.webp";
import LoginForm from "../components/LoginForm";

const LoginPage = () => {
  return (
    <div className="bg-background h-screen text-text flex align-top justify-center gap-10">
      <div className="flex flex-col justify-center items-center">
        <img src={Logo} alt="Inflooms Logo" />
        <p>Grow with us. Influence the future with Inflooms.</p>
      </div>
      <div className="">
        <LoginForm />
      </div>
    </div>
  );
};

export default LoginPage;
