import React from "react";
import SignupForm from "../components/SignUpForm";

const RegistrationPage = () => {
  return (
    <div className="bg-background h-screen text-text flex justify-center gap-10 p-4">
      <div className="flex flex-col justify-center items-center">
        <SignupForm />
      </div>
    </div>
  );
};

export default RegistrationPage;
