import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

export const AuthContext = createContext({
  user: null,
  setUser: () => {},
  logout: () => {},
  refreshUser: () => {},
  updateUser: () => {},
  isLoading: true,
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const verifyUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/verify`,
        { withCredentials: true }
      );
      setUser(response.data);
    } catch (error) {
      console.error("Authentication verification failed:", error);
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    verifyUser();
  }, []);

  const refreshUser = async () => {
    setIsLoading(true);
    await verifyUser();
  };

  const logout = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/logout`,
        {},
        { withCredentials: true }
      );
      setUser(null);
    } catch (error) {
      console.error("Failed to logout:", error);
    }
  };

  const updateUser = async (updatedData) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/users/${user.uuid}`,
        updatedData,
        { withCredentials: true }
      );
      setUser(response.data);
    } catch (error) {
      console.error("Failed to update user:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{ user, setUser, logout, refreshUser, updateUser, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
