import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Modal from "./Modal";

const PasswordChangeForm = () => {
  const [message, setMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Required"),
    newPassword: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .max(40, "Password cannot be more than 40 characters long")
      .matches(/[a-zA-Z]/, "Password must contain letters")
      .matches(/\d/, "Password must contain numbers")
      .required("Required"),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Required"),
  });

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setMessage("");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/change-password`,
        {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        },
        {
          withCredentials: true, // Ensure credentials (cookies) are sent
        }
      )
      .then((response) => {
        setMessage("Password has been changed successfully.");
        setSubmitting(false);
        resetForm();
        setIsSuccessModalOpen(true);
      })
      .catch((error) => {
        let errorMsg = "An unexpected error occurred. Please try again later.";
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMsg = error.response.data.message;
        }
        setErrorMessage(errorMsg);
        setSubmitting(false);
        setIsErrorModalOpen(true);
      });
  };

  const handleForgotPasswordClick = () => {
    navigate("/forgot-password");
  };

  return (
    <div className="flex flex-col h-full text-white px-16 py-8">
      <div className="w-full max-w-md p-8 bg-gray-800 text-white rounded shadow-md">
        <h2 className="text-2xl font-bold mb-4">Change Password</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mb-4">
                <label
                  htmlFor="oldPassword"
                  className="block text-sm font-bold mb-2"
                >
                  Old Password:
                </label>
                <Field
                  type="password"
                  name="oldPassword"
                  className="w-full p-2 bg-gray-700 rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-teal-500"
                  placeholder="Enter your old password"
                />
                <ErrorMessage
                  name="oldPassword"
                  component="div"
                  className="text-red-500 text-xs italic"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="newPassword"
                  className="block text-sm font-bold mb-2"
                >
                  New Password:
                </label>
                <Field
                  type="password"
                  name="newPassword"
                  className="w-full p-2 bg-gray-700 rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-teal-500"
                  placeholder="Enter your new password"
                />
                <ErrorMessage
                  name="newPassword"
                  component="div"
                  className="text-red-500 text-xs italic"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="confirmNewPassword"
                  className="block text-sm font-bold mb-2"
                >
                  Repeat New Password:
                </label>
                <Field
                  type="password"
                  name="confirmNewPassword"
                  className="w-full p-2 bg-gray-700 rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-teal-500"
                  placeholder="Repeat your new password"
                />
                <ErrorMessage
                  name="confirmNewPassword"
                  component="div"
                  className="text-red-500 text-xs italic"
                />
              </div>
              <p className="text-teal-500 text-sm mb-4">
                The password must contain numbers and letters of the Latin
                alphabet. Password length from 8 to 40 characters.
              </p>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="w-full bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-teal-500"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
              <div className="mt-4 text-center">
                <button
                  type="button"
                  className="text-teal-500 hover:underline"
                  onClick={handleForgotPasswordClick}
                >
                  Forgot password?
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <Modal
          isOpen={isSuccessModalOpen}
          onClose={() => setIsSuccessModalOpen(false)}
          title="Success"
        >
          <p>{message}</p>
        </Modal>
        <Modal
          isOpen={isErrorModalOpen}
          onClose={() => setIsErrorModalOpen(false)}
          title="Error"
        >
          <p>{errorMessage}</p>
        </Modal>
      </div>
    </div>
  );
};

export default PasswordChangeForm;
