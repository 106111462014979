import React, { useContext, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import { AuthContext } from "../contexts/AuthContext";

const LoginForm = () => {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");
  const { refreshUser } = useContext(AuthContext);

  const initialValues = {
    identifier: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    identifier: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string().required("Required"),
  });

  const handleSignup = () => {
    navigate("/signup");
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const onSubmit = (values, { setSubmitting }) => {
    setLoginError("");
    axios
      .post(`${process.env.REACT_APP_API_URL}/login`, values, {
        withCredentials: true,
      })
      .then(async (response) => {
        await refreshUser(); // Refresh user data after login
        navigate("/dashboard");
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setLoginError("Email or password incorrect");
        } else {
          setLoginError(
            "An unexpected error occurred. Please try again later."
          );
        }
        setSubmitting(false);
      });
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="w-full max-w-xs">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="bg-inherit shadow-md rounded px-8 pt-6 pb-8 mb-4">
              {loginError && (
                <div className="text-red-500 text-center mb-4">
                  {loginError}
                </div>
              )}
              <div className="mb-4">
                <label
                  htmlFor="identifier"
                  className="block text-sm font-bold mb-2 text-white"
                >
                  E-mail Address:
                </label>
                <Field
                  type="email"
                  name="identifier"
                  className="input"
                  placeholder="Write your mail"
                />
                <ErrorMessage
                  name="identifier"
                  component="div"
                  className="text-red-500 text-xs italic"
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="password"
                  className="block text-sm font-bold mb-2 text-white"
                >
                  Password:
                </label>
                <Field
                  type="password"
                  name="password"
                  className="input"
                  placeholder="Password"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-xs italic"
                />
                <p
                  className="text-blue-500 text-xs italic cursor-pointer"
                  onClick={handleForgotPassword}
                >
                  Forgot your password?
                </p>
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="inflooms-btn w-full rounded-xl"
                  disabled={isSubmitting}
                >
                  Login
                </button>
              </div>
              <p
                className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800 cursor-pointer"
                onClick={handleSignup}
              >
                You're not registered yet?
              </p>

              <div className="mt-2">
                <button
                  type="button"
                  className="inflooms-btn inflooms-btn-green w-full rounded-xl"
                  style={{
                    background:
                      "linear-gradient(111deg, #08A1A5 0%, rgba(54, 83, 174, 0) 100%)",
                    borderColor: "#08A1A5",
                  }}
                  onClick={handleSignup}
                >
                  Create New Account
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LoginForm;
