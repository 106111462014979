import React, { useState } from "react";
import axios from "axios";
import LogoPlaceholder from "../../assets/logoPlaceholder.png";

const Notifications = ({ categories, notifications, fetchNotifications }) => {
  const [activeTab, setActiveTab] = useState("unresolved");

  const getCategoryName = (uuid) => {
    const category = categories.find((cat) => cat.uuid === uuid);
    return category ? category.name : "Unknown Category";
  };

  const handleApprove = async (profileUuid, notificationUuid) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/profiles/approve/${profileUuid}`
      );
      await resolveNotification(notificationUuid);
      fetchNotifications();
    } catch (error) {
      console.error("Error approving platform profile:", error);
    }
  };

  const handleRefuse = async (profileUuid, notificationUuid) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/profiles/${profileUuid}/refuse`
      );
      await resolveNotification(notificationUuid);
      fetchNotifications();
    } catch (error) {
      console.error("Error refusing platform profile:", error);
    }
  };

  const resolveNotification = async (notificationUuid) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/notifications/${notificationUuid}/resolve`
      );
    } catch (error) {
      console.error("Error resolving notification:", error);
    }
  };

  const filteredNotifications = notifications.filter((notification) =>
    activeTab === "unresolved" ? !notification.resolved : notification.resolved
  );

  return (
    <div className="flex flex-col h-full text-white px-16 pb-8">
      <div className="">
        <h1 className="text-5xl">Notifications</h1>
      </div>
      <div className="flex mb-4">
        <button
          className={`px-4 py-2 ${
            activeTab === "unresolved" ? "bg-blue-500" : "bg-gray-500"
          } text-white rounded-l-lg`}
          onClick={() => setActiveTab("unresolved")}
        >
          Unresolved
        </button>
        <button
          className={`px-4 py-2 ${
            activeTab === "resolved" ? "bg-blue-500" : "bg-gray-500"
          } text-white rounded-r-lg`}
          onClick={() => setActiveTab("resolved")}
        >
          Resolved
        </button>
      </div>
      {filteredNotifications.length === 0 ? (
        <div className="border border-infl p-8 rounded-lg shadow-lg w-3/4 max-w-lg text-center">
          <h2 className="text-3xl mb-4">No notifications found</h2>
          <p className="text-base mb-6">
            There are no notifications at the moment. Check back later.
          </p>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          {filteredNotifications.map((notification) => (
            <div
              key={notification.uuid}
              className="border border-infl p-4 rounded-lg shadow-lg"
            >
              <div className="flex flex-row justify-between">
                <div className="mr-8">
                  <img src={LogoPlaceholder} alt="Placeholder" />
                </div>
                <div className="flex flex-col justify-between flex-grow">
                  <div className="flex justify-between">
                    <h2 className="text-2xl mb-4">
                      {notification.platformProfile.platformName} -{" "}
                      {notification.platformProfile.isApproved
                        ? "Approved"
                        : "Pending"}
                    </h2>
                    {activeTab === "unresolved" && (
                      <button
                        className="text-red-500 ml-auto"
                        onClick={() =>
                          handleRefuse(
                            notification.platformProfileUuid,
                            notification.uuid
                          )
                        }
                      >
                        <i className="fas fa-trash-alt"></i>
                      </button>
                    )}
                  </div>
                  <div className="flex flex-row gap-4">
                    <span className="bg-infl p-2 rounded-lg">
                      {getCategoryName(
                        notification.platformProfile.categoryUuid
                      )}
                    </span>
                    <span className="bg-infl p-2 rounded-lg">
                      {notification.platformProfile.followers.toLocaleString()}{" "}
                      Followers
                    </span>
                    <span className="bg-infl p-2 rounded-lg">
                      {notification.platformProfile.averageViews.toLocaleString()}{" "}
                      Average Views
                    </span>
                    <span className="bg-infl p-2 rounded-lg">
                      {notification.platformProfile.priceFrom.toLocaleString()}$
                      to {notification.platformProfile.priceTo.toLocaleString()}
                      $
                    </span>
                  </div>
                  <p className="text-base my-4">
                    Description: {notification.platformProfile.bio}
                  </p>

                  {activeTab === "resolved" && (
                    <p className="text-sm mb-4">
                      Status:{" "}
                      {notification.platformProfile.isApproved
                        ? "Approved"
                        : "Refused"}
                    </p>
                  )}
                </div>
              </div>
              {activeTab === "unresolved" && (
                <div className="flex justify-between mt-4">
                  <button
                    className="inflooms-btn inflooms-btn-green rounded-xl"
                    style={{
                      background:
                        "linear-gradient(111deg, #08A1A5 0%, rgba(54, 83, 174, 0) 100%)",
                      borderColor: "#08A1A5",
                    }}
                    onClick={() =>
                      handleApprove(
                        notification.platformProfileUuid,
                        notification.uuid
                      )
                    }
                  >
                    Approve
                  </button>
                  <button
                    className="inflooms-btn inflooms-btn-red rounded-xl"
                    style={{
                      background:
                        "linear-gradient(111deg, #FF6347 0%, rgba(255, 99, 71, 0) 100%)",
                      borderColor: "#FF6347",
                    }}
                    onClick={() =>
                      handleRefuse(
                        notification.platformProfileUuid,
                        notification.uuid
                      )
                    }
                  >
                    Refuse
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Notifications;
