import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Modal from "./Shared/Modal";

const PasswordResetForm = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const initialValues = {
    newPassword: "",
  };

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string().required("Required"),
  });

  const onSubmit = (values, { setSubmitting }) => {
    setMessage("");
    axios
      .post(`${process.env.REACT_APP_API_URL}/reset-password`, {
        token,
        newPassword: values.newPassword,
      })
      .then((response) => {
        setMessage("Password has been reset successfully.");
        setSubmitting(false);
        setIsSuccessModalOpen(true);
      })
      .catch((error) => {
        let errorMsg = "An unexpected error occurred. Please try again later.";
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMsg = error.response.data.message;
        }
        setErrorMessage(errorMsg);
        setSubmitting(false);
        setIsErrorModalOpen(true);
      });
  };

  const handleSuccessModalClose = () => {
    setIsSuccessModalOpen(false);
    navigate("/login");
  };

  return (
    <div className="flex justify-center items-center h-screen bg-background">
      <div className="w-full max-w-xs">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="bg-inherit shadow-md rounded px-8 pt-6 pb-8 mb-4">
              {message && (
                <div className="text-green-500 text-center mb-4">{message}</div>
              )}
              <div className="mb-4">
                <label
                  htmlFor="newPassword"
                  className="block text-sm font-bold mb-2 text-white"
                >
                  New Password:
                </label>
                <Field
                  type="password"
                  name="newPassword"
                  className="input"
                  placeholder="Enter your new password"
                />
                <ErrorMessage
                  name="newPassword"
                  component="div"
                  className="text-red-500 text-xs italic"
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="inflooms-btn w-full rounded-xl"
                  disabled={isSubmitting}
                >
                  Reset Password
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <Modal
          isOpen={isSuccessModalOpen}
          onClose={handleSuccessModalClose}
          title="Success"
        >
          <p>{message}</p>
        </Modal>
        <Modal
          isOpen={isErrorModalOpen}
          onClose={() => setIsErrorModalOpen(false)}
          title="Error"
        >
          <p>{errorMessage}</p>
        </Modal>
      </div>
    </div>
  );
};

export default PasswordResetForm;
