import React, { useEffect, useState } from "react";
import axios from "axios";
import Filters from "./Filters";
import Results from "./Results";

const SearchComponent = () => {
  const [results, setResults] = useState([]);
  const [, setFilters] = useState({});
  const [categories, setCategories] = useState([]);
  const [, setError] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/categories`,
          { withCredentials: true }
        );
        setCategories(response.data);
      } catch (error) {
        setError(error.response?.data?.message || error.message);
      }
    };

    fetchCategories();
    fetchAllPlatforms();
  }, []);
  const fetchAllPlatforms = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/profiles`,
        { withCredentials: true }
      );
      setResults(response.data);
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    }
  };

  const handleFilterChange = async (filters) => {
    setFilters(filters);

    try {
      const params = {
        ...filters,
        platformUuids: filters.platformUuids.join(","),
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/profiles/search`,
        { params }
      );
      setResults(response.data);
    } catch (error) {
      console.error("Error fetching filtered results:", error);
    }
  };

  return (
    <div className="flex flex-col h-full text-white px-16 py-8 ">
      <div>
        <h1 className="text-5xl">Searching Bloggers</h1>
        <p className="mt-4">Use filters to choose bloggers etc...</p>
      </div>
      <div className="flex mt-8">
        <div className="w-1/3 sticky top-0">
          <Filters
            onFilterChange={handleFilterChange}
            categories={categories}
          />
        </div>
        <div className="flex flex-col w-2/3 ml-8">
          <h2 className="text-3xl w-full py-4">Results</h2>
          <div className=" flex-grow w-full overflow-y-auto h-[calc(100vh-200px)]">
            <Results results={results} categories={categories} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchComponent;
