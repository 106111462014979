import React, { useState } from "react";
import Logo from "../../assets/Logo-color.png";
import Av1 from "../../assets/av-1.png";
import Av2 from "../../assets/av-2.png";
import Av3 from "../../assets/av-3.png";
import Av4 from "../../assets/av-4.png";
import Av5 from "../../assets/av-5.png";
import Av6 from "../../assets/av-6.png";
import Av7 from "../../assets/av-7.png";
import Av8 from "../../assets/av-8.png";
import Filters from "./Filters";

const avatars = [Av1, Av2, Av3, Av4, Av5, Av6, Av7, Av8];

const Selections = () => {
  const [showCollaborationForm, setShowCollaborationForm] = useState(false);
  const [, setFilters] = useState({});
  const categories = [
    {
      uuid: "123",
      name: "Game",
    },
  ];

  const onJoinClick = () => {
    setShowCollaborationForm(true);
  };
  const handleFilterChange = async (filters) => {
    setFilters(filters);

    const params = {
      ...filters,
      platformUuids: filters.platformUuids.join(","),
    };
    alert(params);
  };

  return !showCollaborationForm ? (
    <div className="flex flex-col h-full text-white px-16 pb-8">
      <h1 className="text-5xl">order a unique selection of influencers</h1>
      <p className="mt-10">Don't know how to find the right bloggers?</p>
      <p className="">Don't want to track the collaboration yourself?</p>
      <p className="mb-10">Bloggers don't respond to messages?</p>

      <div className="bg-gradient-to-b from-[#00090C] to-[rgba(54,83,174,0.4)] text-white p-10 rounded-lg shadow-lg flex flex-col items-center space-y-8 w-1/2 border border-primary">
        <h2 className="text-3xl font-bold">Create personal Selection</h2>
        <img src={Logo} alt="Inflooms Logo" className="w-48" />
        <p className="text-center text-lg max-w-2xl">
          We will help you choose sites that suit you by gender, age, geography
          and many other parameters and arrange them in the form of a selection
        </p>
        <div className="flex space-x-2">
          {avatars.map((avatar, index) => (
            <div
              key={index}
              className="w-16 h-16 bg-gray-500 rounded-full overflow-hidden"
            >
              <img
                src={avatar}
                alt={`Profile ${index + 1}`}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
        <div className="grid grid-cols-2 gap-4 text-center bg-white bg-opacity-10 p-4 rounded-lg">
          <div>
            <p className="text-lg font-semibold">Number of Influencers</p>
            <p className="text-2xl font-bold">12345678</p>
          </div>
          <div>
            <p className="text-lg font-semibold">Total Number of Subscribers</p>
            <p className="text-2xl font-bold">12345678</p>
          </div>
          <div>
            <p className="text-lg font-semibold">Total Number of Views</p>
            <p className="text-2xl font-bold">12345678</p>
          </div>
        </div>
        <button
          className="inflooms-btn  rounded-xl w-3/4"
          onClick={onJoinClick}
        >
          Buy a unique selection
        </button>
      </div>
    </div>
  ) : (
    <div className="text-white p-4">
      <Filters onFilterChange={handleFilterChange} categories={categories} />
    </div>
  );
};

export default Selections;
