import React, { useState, useEffect } from "react";
import axios from "axios";
import LogoPlaceholder from "../../assets/logoPlaceholder.png";
import { useAuth } from "../../contexts/AuthContext";

const InfluencerCard = ({ influencer, getCategoryName, onFavoriteChange }) => {
  const { user } = useAuth();
  const [isFavorite, setIsFavorite] = useState(false);
  const imageUrl =
    influencer.images && influencer.images.length > 0
      ? `${process.env.REACT_APP_API_URL}${influencer.images[0].imageUrl}`
      : LogoPlaceholder;

  useEffect(() => {
    const checkFavoriteStatus = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/favorites/${user.uuid}`
        );
        const favorites = response.data;
        const isFav = favorites.some(
          (fav) => fav.platformUuid === influencer.uuid
        );
        setIsFavorite(isFav);
      } catch (error) {
        console.error("Error checking favorite status:", error);
      }
    };

    checkFavoriteStatus();
  }, [user.uuid, influencer.uuid]);

  const handleToggleFavorite = async () => {
    if (isFavorite) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/favorites`, {
          data: {
            clientUuid: user.uuid,
            platformUuid: influencer.uuid,
          },
        });
        setIsFavorite(false);
        if (onFavoriteChange) {
          onFavoriteChange();
        }
      } catch (error) {
        console.error("Error removing from favorites:", error);
      }
    } else {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/favorites`, {
          clientUuid: user.uuid,
          platformUuid: influencer.uuid,
        });
        setIsFavorite(true);
        if (onFavoriteChange) {
          onFavoriteChange();
        }
      } catch (error) {
        console.error("Error adding to favorites:", error);
      }
    }
  };

  return (
    <div
      className={`border p-8 rounded-lg shadow-lg w-full flex flex-row gap-4 ${"border-primary"}`}
    >
      <div className="mr-8">
        <img
          src={imageUrl}
          alt="Placeholder"
          className="w-32 h-32 object-cover rounded-full"
        />
      </div>
      <div className="flex flex-col justify-between flex-grow">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">{influencer.platformName}</h2>
          <button
            className={`text-red-500 ${isFavorite ? "fas" : "far"} fa-heart`}
            onClick={handleToggleFavorite}
          />
        </div>
        <div className="flex flex-row gap-4">
          <span className="bg-primary p-2 rounded-lg">
            {getCategoryName(influencer.categoryUuid)}
          </span>
          <span className="bg-primary p-2 rounded-lg">
            {influencer.followers.toLocaleString()} Followers
          </span>
          <span className="bg-primary p-2 rounded-lg">
            {influencer.averageViews.toLocaleString()} Average Views
          </span>
          <span className="bg-primary p-2 rounded-lg">
            {influencer.priceFrom.toLocaleString()}$ to{" "}
            {influencer.priceTo.toLocaleString()}$
          </span>
        </div>
        <p className="text-base my-4">{influencer.bio}</p>
        <div className="flex justify-end">
          <button
            className="bg-primary rounded-xl p-2"
            onClick={() => alert("Connect")}
          >
            Connect
          </button>
        </div>
      </div>
    </div>
  );
};

export default InfluencerCard;
