import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const AddBlogger = ({
  categories = [],
  platforms = [],
  initialValues,
  onSubmit,
  onCancel,
}) => {
  const defaultValues = {
    platformName: "",
    platformUrl: "",
    platformEmail: "",
    platformGeo: "",
    category: "",
    followers: "",
    averageViews: 0,
    averageAge: "",
    priceFrom: "",
    priceTo: "",
    bio: "",
    platform: "",
    image: null,
    ...initialValues,
  };

  const validationSchema = Yup.object({
    platformName: Yup.string().required("Platform name is required"),
    platformUrl: Yup.string().url("Invalid URL").required("URL is required"),
    platformEmail: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    platformGeo: Yup.string().required("Geographic location is required"),
    category: Yup.string().required("Category is required"),
    followers: Yup.number().required("Number of followers is required").min(0),
    averageViews: Yup.number().required("Total views are required").min(0),
    averageAge: Yup.string()
      .oneOf(["0-12", "13-17", "18-35", "36-50", "51+"], "Invalid age group")
      .required("Average age group is required"),
    priceFrom: Yup.number().required("Starting rate is required").min(0),
    priceTo: Yup.number().required("Ending rate is required").min(0),
    bio: Yup.string(),
    image: Yup.mixed()
      .test(
        "fileSize",
        "The file is too large, maximum size is 2.5MB",
        (value) => !value || (value && value.size <= 2.5 * 1024 * 1024)
      )
      .nullable(),
  });

  return (
    <div className="flex flex-col h-full text-white px-16 py-8">
      <div>
        <h1 className="text-5xl text-white">
          {initialValues ? "Edit Platform" : "Add Platform"}
        </h1>
        <p className="mt-4 text-white">
          {initialValues
            ? "Update the details of your platform."
            : "Give details which describe your platform."}
        </p>
        <Formik
          initialValues={defaultValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form className="mt-6 grid grid-cols-2 gap-4">
              <div className="col-span-1">
                <label htmlFor="platformName" className="block text-white">
                  Name of Channel
                </label>
                <Field
                  type="text"
                  id="platformName"
                  name="platformName"
                  placeholder="Write your name"
                  className="w-full bg-inherit p-2 rounded border border-primary text-sm"
                />
                <ErrorMessage
                  name="platformName"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="category" className="block text-white">
                  Category
                </label>
                <Field
                  as="select"
                  id="category"
                  name="category"
                  className="w-full p-2 rounded border border-primary text-sm bg-inherit"
                >
                  <option className="bg-background text-primary" value="">
                    Choose one...
                  </option>
                  {categories.length > 0 ? (
                    categories.map((category) => (
                      <option
                        className="bg-background text-primary"
                        key={category.uuid}
                        value={category.uuid}
                      >
                        {category.name}
                      </option>
                    ))
                  ) : (
                    <option className="bg-background text-primary" disabled>
                      No categories available
                    </option>
                  )}
                </Field>
                <ErrorMessage
                  name="category"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="platformUrl" className="block text-white">
                  Link on Channel
                </label>
                <Field
                  type="text"
                  id="platformUrl"
                  name="platformUrl"
                  placeholder="www.company.com"
                  className="w-full bg-inherit p-2 rounded border border-primary text-sm"
                />
                <ErrorMessage
                  name="platformUrl"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="averageAge" className="block text-white">
                  Average Age
                </label>
                <Field
                  as="select"
                  id="averageAge"
                  name="averageAge"
                  className="w-full bg-inherit p-2 rounded border border-primary text-sm"
                >
                  <option className="bg-background text-primary" value="">
                    Choose an Age
                  </option>
                  <option className="bg-background text-primary" value="0-12">
                    0-12
                  </option>
                  <option className="bg-background text-primary" value="13-17">
                    13-17
                  </option>
                  <option className="bg-background text-primary" value="18-35">
                    18-35
                  </option>
                  <option className="bg-background text-primary" value="36-50">
                    36-50
                  </option>
                  <option className="bg-background text-primary" value="51+">
                    51+
                  </option>
                </Field>
                <ErrorMessage
                  name="averageAge"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="platformEmail" className="block text-white">
                  Contact Information
                </label>
                <Field
                  type="email"
                  id="platformEmail"
                  name="platformEmail"
                  placeholder="Contact information"
                  className="w-full bg-inherit p-2 rounded border border-primary text-sm"
                />
                <ErrorMessage
                  name="platformEmail"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="col-span-1 grid grid-cols-4 gap-4">
                <div className="col-span-1">
                  <label htmlFor="priceFrom" className="block text-white">
                    Starting Rate
                  </label>
                  <Field
                    type="number"
                    id="priceFrom"
                    name="priceFrom"
                    placeholder="$50"
                    className="w-full bg-inherit p-2 rounded border border-primary text-sm"
                  />
                  <ErrorMessage
                    name="priceFrom"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="col-span-0.5 flex items-end justify-center">
                  <span className="text-white">to</span>
                </div>
                <div className="col-span-1">
                  <label htmlFor="priceTo" className="block text-white">
                    Ending Rate
                  </label>
                  <Field
                    type="number"
                    id="priceTo"
                    name="priceTo"
                    placeholder="$5000"
                    className="w-full bg-inherit p-2 rounded border border-primary text-sm"
                  />
                  <ErrorMessage
                    name="priceTo"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <label htmlFor="platformGeo" className="block text-white">
                  Geo
                </label>
                <Field
                  as="select"
                  id="platformGeo"
                  name="platformGeo"
                  className="w-full bg-inherit p-2 rounded border border-primary text-sm"
                >
                  <option className="bg-background text-primary" value="">
                    Choose a Geo
                  </option>
                  <option className="bg-background text-primary" value="Europe">
                    Europe
                  </option>
                  <option
                    className="bg-background text-primary"
                    value="Latin America"
                  >
                    Latin America
                  </option>
                  <option
                    className="bg-background text-primary"
                    value="North America"
                  >
                    North America
                  </option>
                  <option
                    className="bg-background text-primary"
                    value="Middle East"
                  >
                    Middle East
                  </option>
                  <option className="bg-background text-primary" value="Africa">
                    Africa
                  </option>
                  <option
                    className="bg-background text-primary"
                    value="Asia/Pacific"
                  >
                    Asia/Pacific
                  </option>
                </Field>
                <ErrorMessage
                  name="platformGeo"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="followers" className="block text-white">
                  Followers
                </label>
                <Field
                  type="number"
                  id="followers"
                  name="followers"
                  placeholder="Write your number of subscribers"
                  className="w-full bg-inherit p-2 rounded border border-primary text-sm"
                />
                <ErrorMessage
                  name="followers"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="averageViews" className="block text-white mb-2">
                  Total Views
                </label>
                <Field name="averageViews">
                  {({ field, form }) => (
                    <>
                      <input
                        type="range"
                        id="averageViews"
                        {...field}
                        min="0"
                        max="50000000"
                        step="1000"
                        className="w-full bg-inherit p-2 rounded border border-primary text-primary text-sm range-primary"
                        onChange={(e) => {
                          form.setFieldValue(field.name, e.target.value);
                        }}
                      />
                      <div className="flex items-center mt-2">
                        <input
                          type="text"
                          value={Number(field.value).toLocaleString()}
                          onChange={(e) => {
                            const rawValue = e.target.value.replace(/,/g, "");
                            const value = Math.min(
                              Math.max(parseInt(rawValue, 10) || 0, 0),
                              50000000
                            );
                            form.setFieldValue(field.name, value);
                          }}
                          className="w-24 bg-inherit text-center text-primary text-sm"
                        />
                        <span className="ml-2 text-primary">views</span>
                      </div>
                    </>
                  )}
                </Field>
                <ErrorMessage
                  name="averageViews"
                  component="div"
                  className="text-red-500"
                />
              </div>

              <div className="col-span-1">
                <label htmlFor="bio" className="block text-white">
                  Describe Your Channel
                </label>
                <Field
                  as="textarea"
                  id="bio"
                  name="bio"
                  placeholder="Text..."
                  className="w-full bg-inherit p-2 rounded border border-primary text-sm"
                />
                <ErrorMessage
                  name="bio"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="col-span-2">
                <label htmlFor="image" className="block text-white">
                  Upload Image
                </label>
                <input
                  id="image"
                  name="image"
                  type="file"
                  accept="image/*"
                  onChange={(event) => {
                    setFieldValue("image", event.currentTarget.files[0]);
                  }}
                  className="w-full bg-inherit p-2 rounded border border-primary text-sm"
                />
                <ErrorMessage
                  name="image"
                  component="div"
                  className="text-red-500"
                />
              </div>

              <div className="col-span-2">
                <label className="block text-primary">Platforms</label>
                <div className="flex flex-wrap space-x-4">
                  {platforms.length > 0 ? (
                    platforms.map((platform) => (
                      <label
                        key={platform.uuid}
                        className="inline-flex items-center mb-2"
                      >
                        <Field
                          type="radio"
                          name="platform"
                          value={platform.uuid}
                          checked={values.platform === platform.uuid}
                          onChange={() =>
                            setFieldValue("platform", platform.uuid)
                          }
                        />
                        <span className="ml-2">{platform.name}</span>
                      </label>
                    ))
                  ) : (
                    <span>No platforms available</span>
                  )}
                </div>
              </div>
              <div className="col-span-2 flex justify-between mt-4">
                <button
                  type="submit"
                  className="bg-infl text-white p-2 rounded w-1/4"
                >
                  {initialValues ? "Update" : "Add"}
                </button>
                <button
                  type="button"
                  className="bg-red-500 text-white p-2 rounded w-1/4"
                  onClick={onCancel}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddBlogger;
